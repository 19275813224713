<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">ADI and Spec Grading Quiz</h2>

      <p class="mb-6">
        In the development kitchen for Gatorade, a food chemist was preparing a new flavor by
        measuring out 0.0350 mol of each ingredient into separate beakers. The fire alarm sounded
        after the first amount was placed and the chemist left without labelling the beaker. Because
        this happened late on Friday afternoon, the chemist left for home and came back on Monday.
        After the weekend the chemist didn’t remember what substance was placed in the beaker, but
        has the information below.
      </p>

      <p class="mb-2"><b>Guiding Question</b>: What is the ingredient?</p>

      <v-simple-table class="mb-5">
        <thead>
          <tr>
            <td style="height: 30px; font-weight: bold">Ingredients</td>
          </tr>
          <tr>
            <td style="height: 30px">Dextrose, C<sub>6</sub>H<sub>14</sub>O<sub>7</sub></td>
          </tr>
          <tr>
            <td style="height: 30px">Sucrose, C<sub>12</sub>H<sub>22</sub>O<sub>11</sub></td>
          </tr>
          <tr>
            <td style="height: 30px">Citric Acid, C<sub>6</sub>H<sub>8</sub>O<sub>7</sub></td>
          </tr>
          <tr>
            <td style="height: 30px">Sodium citrate, NaC<sub>6</sub>H<sub>7</sub>O<sub>7</sub></td>
          </tr>
          <tr>
            <td style="height: 30px" class="pb-5">
              Sucrose acetate isobutyrate, C<sub>40</sub>H<sub>62</sub>O<sub>19</sub>
            </td>
          </tr>
          <tr>
            <td style="height: 30px; font-weight: bold">Masses:</td>
          </tr>
          <tr>
            <td style="height: 30px">
              Mass of empty beaker:
              <number-value :value="massBeaker" unit="\text{g}" />
            </td>
          </tr>
          <tr>
            <td style="height: 30px">
              Mass beaker with ingredient:
              <latex-number :number="massSolutionBeaker.toFixed(3)" unit="\text{g}" />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <p class="mb-n3">
        a) <b>Claim:</b> What is your claim? Provide an answer to the guiding question.
      </p>

      <v-radio-group v-model="inputs.claim" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in claimOptions"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
          :label="option.text"
        >
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        b) <b>Evidence:</b> What is your evidence? Provide an answer to the guiding question.
      </p>

      <v-radio-group v-model="inputs.evidence" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in evidenceOptions"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
          :label="option.text"
        >
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        c) <b>Justification:</b> What is your justification? Connect the claim to the evidence with
        scientific theories, laws, concepts, or reasoning (apply information learned to this issue).
      </p>

      <v-radio-group v-model="inputs.justification" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in justificationOptions"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
          :label="option.text"
        >
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'UCIrvineAdiSpecGradingQuizQ6',
  components: {LatexNumber, NumberValue, ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        claim: null,
        evidence: null,
        justification: null,
      },
      claimOptions: [
        {text: 'The unknown is dextrose', value: 'dextrose'},
        {text: 'The unknown is sucrose', value: 'sucrose'},
        {text: 'The unknown is citric acid', value: 'citricAcid'},
        {text: 'The unknown is sodium citrate', value: 'sodiumCitrate'},
        {text: 'The unknown is sucrose acetate isobutyrate', value: 'sucroseAcetate'},
        {text: 'No claim can be made', value: 'noClaim'},
      ],
      justificationOptions: [
        {
          text: 'Conservation of mass requires (mass of beaker + ingredient) = mass of beaker + mass of ingredient',
          value: 'a',
        },
        {text: 'Both mass and moles are intensive properties', value: 'b'},
        {text: 'Homogeneous mixtures require this relationship', value: 'c'},
        {text: 'For sugars, mass = moles', value: 'd'},
        {
          text: 'The ratio of mass to moles is the formula weight of the unknown compound',
          value: 'e',
        },
      ],
    };
  },
  computed: {
    massBeaker() {
      return this.taskState.getValueBySymbol('massBeaker').content;
    },
    ingredient() {
      return this.taskState.getValueBySymbol('ingredient').content;
    },
    molarMass() {
      if (this.ingredient.value === 1) {
        return 180.156;
      } else if (this.ingredient.value === 2) {
        return 342.2965;
      } else if (this.ingredient.value === 3) {
        return 192.124;
      } else if (this.ingredient.value === 4) {
        return 214.106;
      } else {
        return -100;
      }
    },
    massSolutionBeaker() {
      return this.massBeaker.toFloat() + 0.035 * this.molarMass;
    },
    massSolution() {
      return this.massSolutionBeaker - this.massBeaker.toFloat();
    },
    evidenceOptions() {
      return [
        {
          text: 'The mass of the ingredient is greater than the moles',
          value: 'a',
        },
        {
          text:
            'The mass of the ingredient is ' +
            this.massSolutionBeaker.toFixed(3) +
            '\u00A0g - ' +
            this.massBeaker.toFloat().toFixed(3) +
            '\u00A0g = ' +
            this.massSolution.toFixed(3) +
            '\u00A0g',
          value: 'b',
        },
        {
          text: 'The mass of the ingredient cannot be determined with the information provided',
          value: 'c',
        },
        {
          text: 'The number of moles must equal 1',
          value: 'd',
        },
        {
          text:
            'The mass of the ingredient multiplied by the moles given equals ' +
            this.molarMass +
            '\u00A0g/mol',
          value: 'e',
        },
      ];
    },
  },
};
</script>
<style scoped></style>
